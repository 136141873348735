/*import {OverlayScrollbars} from "overlayscrollbars";*/

const headerComponent = document.getElementsByClassName('header')[0];
const topHeaderComponent = document.getElementsByClassName('top-header')[0];
const toTopBtnComponent = document.getElementsByClassName('btn-to-top')[0];
// Header scroll
const scrollContainer = () => document.documentElement || document.body;

document.addEventListener('scroll', () => {
  if (scrollContainer().scrollTop > 50) {
    headerComponent.classList.add('scrolled');
    topHeaderComponent.classList.add('scrolled');
    toTopBtnComponent.classList.add('is-visible');
  } else if (scrollContainer().scrollTop == 0) {
    headerComponent.classList.remove('scrolled');
    topHeaderComponent.classList.remove('scrolled');
    toTopBtnComponent.classList.remove('is-visible');
  }
});

// menu handlers

// check mobile menu show/hide condition
const mobileMenuStartPoint = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue(
    '--mobile-menu-start-point'
  )
);
let isMobileMenuEnable =
  window.outerWidth <= mobileMenuStartPoint ||
  document.querySelector('.header-mobile');

/*const dropdownToggle = document.querySelectorAll('.menu-dropdown');

dropdownToggle.forEach((dropdown) => {
  const toggle = dropdown.querySelector('.menu-dropdown__arrow');
  toggle.addEventListener('click', function (e) {
    if (isMobileMenuEnable) {
      const menuItem = this.closest('.menu__item.menu-dropdown');
      const menuList = menuItem.querySelector('.menu-dropdown__list');
      const dropdownMenu = this.nextElementSibling;

      // close all opened sub menu
      const activeDropdowns = document.querySelectorAll(
        '.menu-item.dropdown.active .dropdown-menu'
      );
      activeDropdowns.forEach((menu) => {
        menu.style.display = 'none';
        menu.closest('.dropdown').classList.remove('active');
      });
      document.querySelectorAll('.menu-dropdown__list').forEach((list) => {
        list.classList.remove('active');
      });

      // open current submenu
      menuItem.classList.toggle('active');
      menuList.classList.toggle('active');
      if (menuItem.classList.contains('active')) {
        e.preventDefault();
        dropdownMenu.style.display = 'block';
      }
    }
  });
});*/

if (document.getElementsByClassName('menu-dropdown')[0]) {
  const dropdownToggleArr = document.querySelectorAll('.menu-dropdown');
  const dropdownArrowArr = document.querySelectorAll('.menu-dropdown__arrow');
  const dropdownListArr = document.querySelectorAll('.menu-dropdown__list');

  dropdownToggleArr.forEach((btn, i) => {
    const arrow = btn.querySelector('.menu-dropdown__arrow');
    const link = btn.querySelector('.menu__link');

    link.addEventListener('click', (e) => {
      if(window.innerWidth <= 1024) {
        if (!link.classList.contains('is-opened')) {
          e.preventDefault();
        }
        link.classList.add('is-opened');

        openLogic();
      } else {
        openLogic();
      }
    });

    arrow.addEventListener('click', () => {
      if (!arrow.classList.contains('active')) {
        openLogic();
      } else {
        for (let j = 0; j < dropdownToggleArr.length; j++) {
          dropdownArrowArr[j].classList.remove('active');
          dropdownListArr[j].classList.remove('active');
        }
      }
    });

    function openLogic() {
      for (let j = 0; j < dropdownToggleArr.length; j++) {
        dropdownArrowArr[j].classList.remove('active');
        dropdownListArr[j].classList.remove('active');
      }

      arrow.classList.toggle('active');
      dropdownListArr[i].classList.toggle('active');
    }

    if(dropdownToggleArr[i].classList.contains('columns-type')) {
      const wrapper = dropdownToggleArr[i].querySelector('.scroll-container');
      /*const osInstance = OverlayScrollbars(wrapper, {});*/
      dropdownListArr[i].style.paddingRight = 0;
    } else {
      const dropdownItemsArr = dropdownListArr[i].querySelectorAll('li');
      if (dropdownItemsArr.length >= 6) {
        const wrapper = document.createElement('ul');
        wrapper.classList.add('scroll-container');

        dropdownItemsArr.forEach(item => {
          wrapper.appendChild(item);
        });

        dropdownListArr[i].appendChild(wrapper);

        /*const scroll = new SimpleBar(wrapper);*/
        /*const osInstance = OverlayScrollbars(wrapper, {});*/
        dropdownListArr[i].style.paddingRight = 0;
      }
    }
  });
}

// toggle menu handler
function menuToggleFunc() {
  const menuToggle = document.querySelector('.menu-toggle');
  const navbarNav = document.querySelector('.navbar-nav');
  const headerCloseWrapper = document.querySelector('.header-close-wrapper');

  menuToggle.classList.toggle('active');
  navbarNav.classList.toggle('active');
  headerComponent.classList.toggle('open-menu');
  topHeaderComponent.classList.toggle('open-menu');
  headerCloseWrapper.classList.toggle('active');
  toTopBtnComponent.classList.toggle('is-visible');

  // LockScroll when burger open and enable when closed and enable scroll on menu
  if (scrollLock.getScrollState()) {
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');
  } else {
    scrollLock.clearQueueScrollLocks();
    scrollLock.enablePageScroll();
  }
}

// menu update function
function updateMenu() {
  isMobileMenuEnable =
    window.outerWidth <= mobileMenuStartPoint ||
    document.querySelector('.mobile-header');

  if (!isMobileMenuEnable) {
    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
    dropdownMenus.forEach((menu) => {
      menu.style.display = '';
    });

    const headerCloseWrapper = document.querySelector('.header-close-wrapper');
    headerCloseWrapper.classList.remove('active');

    const activeMenuItems = document.querySelectorAll('.menu-item.active');
    activeMenuItems.forEach((item) => {
      item.classList.remove('active');
    });

    const navbarNav = document.querySelector('.navbar-nav');
    navbarNav.classList.remove('active');

    headerComponent.classList.remove('open-menu');
    topHeaderComponent.classList.remove('open-menu');

    const menuToggle = document.querySelector('.menu-toggle');
    menuToggle.classList.remove('active');

    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}

window.addEventListener('resize', updateMenu);
window.addEventListener('orientationchange', updateMenu);

// end of toggle menu handler

const menuToggle = document.querySelector('.menu-toggle');
const headerCloseWrapper = document.querySelector('.header-close-wrapper');

menuToggle.addEventListener('click', menuToggleFunc);
headerCloseWrapper.addEventListener('click', menuToggleFunc);

// Hide and show Header when scroll up
function headerSticky() {
  let prevScrollPos = window.pageYOffset;

  window.addEventListener('scroll', () => {
    const currentScrollPos = window.pageYOffset;
    const headerHeight = headerComponent.scrollHeight;
    const topHeaderHeight = topHeaderComponent.scrollHeight;

    if (window.scrollY > 0) {
      if (prevScrollPos > currentScrollPos) {
        /*headerComponent.style.top = 0;*/
        headerComponent.classList.add('scrolled');
        topHeaderComponent.style.top = 0;
        topHeaderComponent.classList.add('scrolled');
        toTopBtnComponent.classList.add('is-visible');
        headerComponent.classList.remove('active-top');
        if (headerComponent.classList.contains('scrolled')) {
          headerComponent.style.top = `${topHeaderHeight}px`;
          headerComponent.classList.add('active-top');
        }
      } else {
        /*headerComponent.style.top = `-${headerHeight + 3}px`;*/
        topHeaderComponent.style.top = `-${topHeaderHeight + 3}px`;
        headerComponent.style.top = 0;
        headerComponent.classList.remove('active-top');
      }
    }

    if (window.scrollY === 0) {
      headerComponent.classList.remove('scrolled');
      headerComponent.classList.remove('active-top')
      topHeaderComponent.classList.remove('scrolled');
      toTopBtnComponent.classList.remove('is-visible');
      headerComponent.style.top = 0;
    }

    prevScrollPos = currentScrollPos;
  });

  if (window.scrollY !== 0) {
    headerComponent.classList.add('scrolled');
    topHeaderComponent.classList.add('scrolled');
    toTopBtnComponent.classList.add('is-visible');
  }
}

headerSticky();

const languageDropdown = document.getElementsByClassName(
  'top-header__language'
)[0];

if (languageDropdown) {
  languageDropdown.addEventListener('click', () => {
    if (window.innerWidth <= 1024) {
      languageDropdown.classList.toggle('active');
    }
  });

  document.addEventListener('click', (event) => {
    if (window.innerWidth <= 1024) {
      const targetElement = event.target;
      const isClickInsideLanguageDropdown =
        languageDropdown.contains(targetElement);

      if (!isClickInsideLanguageDropdown) {
        languageDropdown.classList.remove('active');
      }
    }
  });
}
