window.accordionInit = function accordionInit(accordionEl) {
  if (!accordionEl.classList.contains('is-init')) {
    accordionEl.classList.add('is-init');

    const itemsArr = accordionEl.querySelectorAll('.accordion-item');

    itemsArr.forEach((item, i) => {
      const btn = item.querySelector('.accordion-toggle');
      const content = item.querySelector('.accordion-content');

      /*item.addEventListener('click', (event) => {
        if (event.target !== btn) {
          openAccordion(item);
        } else if (event.target === btn && item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });*/

      btn.addEventListener('click', (event) => {
        if (item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });

      function openAccordion(item) {
        for (let j = 0; j < itemsArr.length; j++) {
          itemsArr[j].classList.remove('active');
        }

        item.classList.add('active');
        content.style.maxHeight = `${itemCalcMaxHeight(item, content)}px`;
      }

      function itemCalcMaxHeight(item, content) {
        const children = Array.from(content.children);
        const totalHeight = children.reduce((acc, child) => {
          const childHeight = child.offsetHeight;
          return acc + childHeight;
        }, 0);
        return totalHeight + 100;
      }
    });
  }
};

window.accordionSecondInit = function accordionSecondInit(accordionSecondEl) {
  if (!accordionSecondEl.classList.contains('is-init')) {
    accordionSecondEl.classList.add('is-init');

    const parentContentEl = accordionSecondEl.parentElement;

    const itemsArr = accordionSecondEl.querySelectorAll('.accordion-second-item');

    itemsArr.forEach((item, i) => {
      const btn = item.querySelector('.accordion-second-toggle');
      const content = item.querySelector('.accordion-second-content');

      /*item.addEventListener('click', (event) => {
        if (event.target !== btn) {
          openAccordion(item);
        } else if (event.target === btn && item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });*/

      btn.addEventListener('click', (event) => {
        if (item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });

      function openAccordion(item) {
        for (let j = 0; j < itemsArr.length; j++) {
          itemsArr[j].classList.remove('active');
        }

        item.classList.add('active');
        content.style.maxHeight = `${itemCalcMaxHeight(item, content)}px`;
        parentContentEl.style.maxHeight = `${itemCalcMaxHeight(item, content) + parentContentEl.clientHeight + 50}px`;
      }

      function itemCalcMaxHeight(item, content) {
        const children = Array.from(content.children);
        const totalHeight = children.reduce((acc, child) => {
          const childHeight = child.offsetHeight;
          return acc + childHeight;
        }, 0);
        return totalHeight + 100;
      }
    });
  }
};

const textBlockAccordion = document.getElementsByClassName('text-block-section__accordion')[0];

if (textBlockAccordion) {
  const textBlockAccordionArr = document.querySelectorAll('.text-block-section__accordion');

  textBlockAccordionArr.forEach((accordionEl) => {
    accordionInit(accordionEl);
  });
}

const textBlockAccordionSecond = document.getElementsByClassName('text-block-section__second-accordion')[0];

if (textBlockAccordionSecond) {
  const textBlockAccordionSecondArr = document.querySelectorAll('.text-block-section__second-accordion');

  textBlockAccordionSecondArr.forEach((accordionSecondEl) => {
    accordionSecondInit(accordionSecondEl);
  });
}

const faqAccordion = document.getElementsByClassName('faq-section__accordion')[0];

if (faqAccordion) {
  const faqAccordionArr = document.querySelectorAll('.faq-section__accordion');

  faqAccordionArr.forEach((accordionEl) => {
    accordionInit(accordionEl);
  });
}
