import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('filters-slider')[0]) {
  const filtersSlidersArr = document.querySelectorAll('.filters-slider');
  filtersSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let breakpoints = {
      451: {
        spaceBetween: 15,
        slidesPerView: 3,
      },
      651: {
        spaceBetween: 15,
        slidesPerView: 4,
      },
      901: {
        spaceBetween: 15,
        slidesPerView: 5,
      },
      1025: {
        spaceBetween: 15,
        slidesPerView: 6,
      },
      1200: {
        spaceBetween: 20,
        slidesPerView: 6,
      },
      1300: {
        spaceBetween: 31,
        slidesPerView: 7,
      },
    };

    if (sliderEl.classList.contains('contact-section__slider')) {
      breakpoints = {
        451: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        651: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        901: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
        1200: {
          spaceBetween: 20,
          slidesPerView: 4,
        },
        1300: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
      };
    } else if (sliderEl.classList.contains('offices-section__slider')) {
      breakpoints = {
        451: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        651: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        901: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 5,
        },
        1200: {
          spaceBetween: 20,
          slidesPerView: 6,
        },
        1300: {
          spaceBetween: 30,
          slidesPerView: 6,
        },
      };
    } else if (sliderEl.classList.contains('insight-search-section__slider')) {
      breakpoints = {
        370: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        451: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        651: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
        901: {
          spaceBetween: 15,
          slidesPerView: 5,
        },
        1025: {
          spaceBetween: 15,
          slidesPerView: 6,
        },
        1200: {
          spaceBetween: 15,
          slidesPerView: 7,
        },
        1300: {
          spaceBetween: 15,
          slidesPerView: 8,
        },
      };
    }

    const filtersSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 2,
      spaceBetween: 15,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      /*autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },*/
      /*navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },*/
      breakpoints: breakpoints,
    });

    /*swiperObserver(filtersSwiper);*/
  });
}
