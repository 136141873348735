const ourValuesContainer = document.getElementsByClassName('text-block-section__values')[0];
if(ourValuesContainer) {
  const ourValuesItemsArr = ourValuesContainer.querySelectorAll('.text-block-section__value');

  if(ourValuesItemsArr){
    ourValuesItemsArr.forEach(item => {
      const hiddenEl = item.querySelector('.text-block-section__hidden-value-text');
      const icon = item.querySelector('.text-block-section__value-icon');
      if(hiddenEl){
        icon.style.opacity = 1;
        const hiddenChildEl = hiddenEl.querySelector('.text-block-section__value-text');
        item.addEventListener('click', () => {
          if(!item.classList.contains('active')) {
            for (let i = 0; i < ourValuesItemsArr.length; i++) {
              ourValuesItemsArr[i].classList.remove('active');
            }

            item.classList.add('active');
            hiddenEl.maxHeight = `${hiddenChildEl.clientHeight + 100}px`;
          } else {
            item.classList.remove('active');
            hiddenEl.maxHeight = 0;
          }
        })
      } else  {
        icon.style.opacity = 0;
        item.style.cursor = 'default';
      }
    })
  }

}