const articleText = document.querySelector('.article-section__text');

if (articleText) {
  const h4Arr = articleText.querySelectorAll('h4');
  const h3Arr = articleText.querySelectorAll('h3');
  const h2Arr = articleText.querySelectorAll('h2');
  const olArr = articleText.querySelectorAll('ol');
  const ulArr = articleText.querySelectorAll('ul');

  checkPrevTag(h4Arr);
  checkPrevTag(h3Arr);
  checkPrevTag(h2Arr);
  checkPrevTag(olArr);
  checkPrevTag(ulArr);

  function checkPrevTag(tagArr) {
    tagArr.forEach(tagElement => {
      let prevNode = tagElement.previousSibling;

      while (prevNode && prevNode.nodeType === Node.TEXT_NODE && !prevNode.textContent.trim()) {
        prevNode = prevNode.previousSibling;
      }

      if (!prevNode || prevNode.nodeType !== Node.ELEMENT_NODE) {
        tagElement.classList.add('pt-add');
      }
    });
  }
}