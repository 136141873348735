import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('work-section__slider')[0]) {
  const workSlidersArr = document.querySelectorAll('.work-section__slider');
  workSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const workItemsArr = sliderEl.querySelectorAll('.work-section__item');

    const workSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: {
        451: {
          spaceBetween: 16,
          slidesPerView: 1.5,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        901: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      },
    });

    workItemsArr.forEach(item => {
      const itemText = item.querySelector('.work-section__item-text');
      const openBtn = item.querySelector('.work-section__item-links .link-btn');

      const divWrapper = document.createElement('div');
      divWrapper.classList.add('work-section__item-hidden');

      let firstChildSkipped = false;
      while (itemText.children.length > 1) {
        const childElement = itemText.children[1];
        if (firstChildSkipped) {
          divWrapper.appendChild(childElement);
        } else {
          firstChildSkipped = true;
        }
      }

      itemText.insertBefore(divWrapper, itemText.firstChild);
      itemText.style.flexDirection = 'column-reverse';

      if(divWrapper.childNodes.length === 0) {
        openBtn.style.display = 'none';
      }

      openBtn.addEventListener('click', (e) => {
        const icon = openBtn.querySelector('i');
        e.preventDefault();

        if(!itemText.classList.contains('active')) {
          itemText.classList.add('active');
          icon.classList.remove('icon-plus');
          icon.classList.add('icon-minus');
        } else {
          itemText.classList.remove('active');
          icon.classList.add('icon-plus');
          icon.classList.remove('icon-minus');
        }
      });
    });

    swiperObserver(workSwiper);
  });
}
