const jobSearchSection =
  document.getElementsByClassName('job-search-section')[0];

if (jobSearchSection) {
  const jobFiltersOpenBtn = jobSearchSection.querySelector(
    '.job-search-section__open-btn-toggle'
  );
  const jobFiltersOpenContent = jobSearchSection.querySelector(
    '.job-search-section__open-filters'
  );

  jobFiltersOpenBtn.addEventListener('click', () => {
    if (jobFiltersOpenBtn.classList.contains('active')) {
      closeFilters();
    } else {
      openFilters();
    }
  });

  function openFilters() {
    jobFiltersOpenBtn.classList.add('active');
    jobFiltersOpenContent.classList.add('active');

    setTimeout(function () {
      jobFiltersOpenContent.style.overflow = 'visible';
    }, 300);
  }

  function closeFilters() {
    jobFiltersOpenBtn.classList.remove('active');
    jobFiltersOpenContent.classList.remove('active');
    jobFiltersOpenContent.style.overflow = 'hidden';
  }
}
