import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('teams-section__slider')[0]) {
  const teamsSlidersArr = document.querySelectorAll('.teams-section__slider');
  teamsSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let pauseOnMouseEnter = window.innerWidth > 1024;

    let breakpoints = {
      451: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      651: {
        spaceBetween: 16,
        slidesPerView: 3,
      },
      901: {
        spaceBetween: 16,
        slidesPerView: 3,
      },
      1025: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
      1300: {
        spaceBetween: 30,
        slidesPerView: 4,
      },
    }

    if (sliderEl.classList.contains('big-card-type')) {
      breakpoints = {
        451: {
          spaceBetween: 16,
          slidesPerView: 1.2,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 1.5,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        1300: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
      }
    }

    if (sliderEl.classList.contains('equities-type')) {
      breakpoints = {
        451: {
          spaceBetween: 16,
          slidesPerView: 1.2,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        1020: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1300: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      }
    }

    const teamsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3200,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: breakpoints,
    });

    swiperObserver(teamsSwiper);
  });
}
