import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import SimpleBar from 'simplebar';

if (document.getElementsByClassName('classroom-section__slider')[0]) {
  const classroomSlidersArr = document.querySelectorAll(
    '.classroom-section__slider'
  );
  const classroomSliderButtonsArr = document.querySelectorAll(
    '.classroom-section__slider-buttons'
  );
  classroomSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 3) {
      loop = true;
      loopedSlides = slides.length * 3;
    }

    const classroomSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 22,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl:
          classroomSliderButtonsArr[sliderIdx].querySelector(
            '.slider-btn.prev'
          ),
        nextEl:
          classroomSliderButtonsArr[sliderIdx].querySelector(
            '.slider-btn.next'
          ),
      },
      breakpoints: {
        1025: {
          spaceBetween: 24,
        },
        1200: {
          spaceBetween: 30,
        },
      },
    });

    swiperObserver(classroomSwiper);

    setTimeout(function () {
      const classroomItemsArr = sliderEl.querySelectorAll(
        '.classroom-section__item-text'
      );
      console.log(classroomItemsArr.length);
      for (let i = 0; i < classroomItemsArr.length; i++) {
        new SimpleBar(classroomItemsArr[i]);
      }
    }, 100);
  });
}
