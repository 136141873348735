function widthFitContent(selector) {
    const elArr = document.querySelectorAll(selector);
    if (elArr.length) {
        function setWidth() {
            elArr.forEach(el => {
                let text = el.innerText;
                el.innerHTML = `<span style="display: inline;">${text}</span>`
                let span = el.querySelector("span");
                el.classList.add('fit-width-initialized');
                el.style.width = '100%';
                el.offsetWidth > span.offsetWidth ? el.style.width = `${span.offsetWidth + 1}px` : false;

            })
        }

        window.onresize = () => setWidth()
        setTimeout(setWidth, 10);
    }
}

widthFitContent('.accordion-section__item-link a');

window.setBoxMbCounter = function setBoxMbCounter(scrollContainer, listenTagsName) {
    let tags = scrollContainer.querySelectorAll(listenTagsName);
    if (tags) {
        let mbCount = 0;
        let parentHeight = scrollContainer.clientHeight;
        let targetHeight = 0;

        tags.forEach((tag) => {
            let mb = parseFloat(window.getComputedStyle(tag)['marginBottom']);
            targetHeight += tag.offsetHeight + mb;
            if (parentHeight > targetHeight) {
                mbCount++;
            }
        });

        scrollContainer.style.setProperty('--mb-count', mbCount);
    }
}

