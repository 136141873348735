import Swiper, { Autoplay, EffectFade, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('capabilities-section__slider')[0]) {
  const capabilitiesListArr = document.querySelectorAll(
    '.capabilities-section__list'
  );
  const capabilitiesSliderButtonsArr = document.querySelectorAll(
    '.capabilities-section__slider-buttons'
  );
  const capabilitiesSlidersArr = document.querySelectorAll(
    '.capabilities-section__slider'
  );
  capabilitiesSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let effect = 'fade';
    if (window.innerWidth <= 951) {
      effect = 'slide';
    }

    const capabilitiesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, EffectFade],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      autoHeight: true,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
      navigation: {
        prevEl:
          capabilitiesSliderButtonsArr[sliderIdx].querySelector(
            '.slider-btn.prev'
          ),
        nextEl:
          capabilitiesSliderButtonsArr[sliderIdx].querySelector(
            '.slider-btn.next'
          ),
      },
      effect: effect,

      breakpoints: {
        320: {
          /*effect: 'slide',*/
        },
        651: {
          /*effect: 'fade',*/
          autoplay: false,
          allowTouchMove: false,
        },
      },
    });

    /*swiperObserver(capabilitiesSwiper);*/

    const capabilitiesListItemsArr =
      capabilitiesListArr[sliderIdx].querySelectorAll('li');
    capabilitiesListItemsArr.forEach((itemEl, itemIdx) => {
      itemEl.addEventListener('click', () => {
        for (let i = 0; i < capabilitiesListItemsArr.length; i++) {
          capabilitiesListItemsArr[i].classList.remove('active');
        }

        capabilitiesSwiper.slideTo(itemIdx);
        itemEl.classList.add('active');
      });
    });
  });
}
