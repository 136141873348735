import Swiper, { Autoplay, EffectFade, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('recruit-section__slider')[0]) {
  const recruitListArr = document.querySelectorAll('.recruit-section__list');
  const recruitSliderButtonsArr = document.querySelectorAll(
    '.recruit-section__slider-buttons'
  );
  const recruitSlidersArr = document.querySelectorAll(
    '.recruit-section__slider'
  );
  recruitSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let effect = 'fade';
    if (window.innerWidth <= 951) {
      effect = 'slide';
    }

    const recruitSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, EffectFade],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
      navigation: {
        prevEl:
          recruitSliderButtonsArr[sliderIdx].querySelector('.slider-btn.prev'),
        nextEl:
          recruitSliderButtonsArr[sliderIdx].querySelector('.slider-btn.next'),
      },
      effect: effect,

      breakpoints: {
        320: {
          /*effect: 'slide',*/
        },
        651: {
          /*effect: 'fade',*/
          autoplay: false,
          allowTouchMove: false,
        },
      },
    });

    /*swiperObserver(recruitSwiper);*/

    const recruitListItemsArr =
      recruitListArr[sliderIdx].querySelectorAll('li');
    recruitListItemsArr.forEach((itemEl, itemIdx) => {
      itemEl.addEventListener('click', () => {
        for (let i = 0; i < recruitListItemsArr.length; i++) {
          recruitListItemsArr[i].classList.remove('active');
        }

        recruitSwiper.slideTo(itemIdx);
        itemEl.classList.add('active');
      });
    });
  });
}
