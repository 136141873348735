function accordionFunc(elem) {
  const itemsArr = elem.querySelectorAll('[data-accordion-item]');
  itemsArr.forEach((item, i) => {
    item.addEventListener('click', (e) => {
      const content = item.querySelector('.accordion-section__item-content-scroll')
      console.log(!content.contains(e.target))
      if(!content.contains(e.target)) {
        /*const isClickable = (element) => {
        if (element === item) {
          return true;
        }
        if (element.hasAttribute('data-accordion-not-toggle')) {
          return false;
        }
        if (element.parentElement) {
          return isClickable(element.parentElement);
        }
        return true;
      };

      if (isClickable(e.target)) {
        item.classList.toggle('active');
      }*/

        if (item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          for (let j = 0; j < itemsArr.length; j++) {
            if (itemsArr[j].classList.contains('active')) {
              itemsArr[j].classList.remove('active');
            }
          }

          item.classList.add('active');
        }
      }
    });
  });
}

if (document.querySelector('[data-accordion]')) {
  const accordionsArr = document.querySelectorAll('[data-accordion]');
  accordionsArr.forEach((accordion) => {
    accordionFunc(accordion);
  });
}

/*if (item.classList.contains('active')) {
        item.classList.remove('active');
      } else {
        for (let j = 0; j < itemsArr.length; j++) {
          if (itemsArr[j].classList.contains('active')) {
            itemsArr[j].classList.remove('active');
          }
        }

        item.classList.add('active');
      }*/
