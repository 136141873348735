import Swiper, { Autoplay, EffectFade, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('specialism-section__slider')[0]) {
  const specialismListArr = document.querySelectorAll('.specialism-section__list');
  const specialismSliderButtonsArr = document.querySelectorAll('.specialism-section__slider-buttons');
  const specialismSlidersArr = document.querySelectorAll('.specialism-section__slider');
  specialismSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let effect = 'fade';
    if(window.innerWidth <= 640) {
      effect = 'slide';
    }

    const specialismSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, EffectFade],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
      navigation: {
        prevEl: specialismSliderButtonsArr[sliderIdx].querySelector('.slider-btn.prev'),
        nextEl: specialismSliderButtonsArr[sliderIdx].querySelector('.slider-btn.next'),
      },
      effect: effect,
      fadeEffect:{
        crossFade: true,
      },

      breakpoints: {
        320: {
          /*effect: 'slide',*/
        },
        651: {
          /*effect: 'fade',*/
          autoplay: false,
          allowTouchMove: false,
        },
      },
    });

    /*swiperObserver(specialismSwiper);*/

    const specialismListItemsArr = specialismListArr[sliderIdx].querySelectorAll('li');
    specialismListItemsArr.forEach((itemEl, itemIdx) => {
      itemEl.addEventListener('click', () => {
        for (let i = 0; i < specialismListItemsArr.length; i++) {
          specialismListItemsArr[i].classList.remove('active');
        }

        specialismSwiper.slideTo(itemIdx);
        itemEl.classList.add('active');
      })
    })
  });
}
