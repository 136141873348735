import Swiper, {Autoplay, Controller, Navigation} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('stages-section__slider')[0]) {
  const stagesSliderContainer = document.querySelector('.stages-section__slider-container');
  const stagesSlider = stagesSliderContainer.querySelector('.stages-section__slider');
  const stagesNavSlider = document.querySelector('.stages-section__nav-slider');
  const stagesNavItemsArr = stagesNavSlider.querySelectorAll('.stages-section__nav-item');

  let pauseOnMouseEnter = window.innerWidth > 1024;

  const stagesNavSwiper = new Swiper(stagesNavSlider, {
    modules: [Autoplay, Navigation, Controller],
    slidesPerView: 'auto',
    spaceBetween: 0,
    threshold: 10,
    slideToClickedSlide: true,
    /*loop: loop,
    loopedSlides: loopedSlides,*/
  });

  const stagesSwiper = new Swiper(stagesSlider, {
    modules: [Autoplay, Navigation, Controller],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    /*loop: loop,
    loopedSlides: loopedSlides,*/
    /*autoplay: {
      delay: 3200,
      disableOnInteraction: false,
      pauseOnMouseEnter: pauseOnMouseEnter,
    },*/

    navigation: {
      prevEl: stagesSliderContainer.querySelector('.slider-buttons .slider-btn.prev'),
      nextEl: stagesSliderContainer.querySelector('.slider-buttons .slider-btn.next'),
    },
  });

  stagesNavItemsArr.forEach((navEl, navIdx) => {
    navEl.addEventListener('click', () => {
      for (let i = 0; i < stagesNavItemsArr.length; i++) {
        stagesNavItemsArr[i].classList.remove('active');
      }

      navEl.classList.add('active');

      stagesSwiper.slideTo(navIdx);
    })
  })

  stagesNavSwiper.controller.control = stagesSwiper
  stagesSwiper.controller.control = stagesNavSwiper

  /*swiperObserver(stagesNavSwiper);*/
}
